<template>
    <div class="main-panel">
        <!-- RETURN BUTTON -->
        <div class="return_button">
            <a @click="goToResidences()">
                <i class="fas fa-arrow-left"></i>
                <span class="mobile">
                    {{ $t("navigation.listBack") }}
                </span>
            </a>
        </div>

        <!-- INFORMATIONS -->
        <div
            class="informations-bloc mobile"
            :class="{
                greyType: isBasic,
                orangeType: isSimplified,
                blueType: isComplex,
            }"
            @click="checkRight('G_RESIDENCES')"
        >
            <Infos
                :ipass="ipass"
                :address="address"
                :name="name"
                :comment="comment"
                :type="type"
                @editAction="editAction()"
            />
        </div>

        <hr class="mobile" />

        <!-- TABS -->
        <div class="tabs-bloc">
            <ul class="list-group">
                <!-- Details -->
                <li
                    class="list-group-item"
                    :class="{ activeTab: activeTab == 'details' }"
                    @click="changeTab('details')"
                >
                    <i class="fas fa-city"></i>
                    <span class="mobile">
                        {{ $t("entityDetails.tabs.residenceDetails") }}
                    </span>
                </li>
                <!-- Authorizations -->
                <li
                    v-if="checkRight('G_AUTOS')"
                    class="list-group-item"
                    :class="{ activeTab: activeTab == 'authorizations' }"
                    @click="changeTab('authorizations')"
                >
                    <i class="far fa-lightbulb"></i>
                    <span class="mobile">
                        {{ $t("entityDetails.tabs.authorisations") }}
                    </span>
                </li>
                <!-- Codes -->
                <li
                    v-if="checkRight('G_CODE_CLAV') || checkRight('G_CODE_TEL')"
                    class="list-group-item"
                    :class="{ activeTab: activeTab == 'codes' }"
                    @click="changeTab('codes')"
                >
                    <i class="far fa-keyboard"></i>
                    <span class="mobile">
                        {{ $t("entityDetails.tabs.codes") }}
                    </span>
                </li>
                <!-- Central units -->
                <li
                    class="list-group-item"
                    :class="{ activeTab: activeTab == 'central-units' }"
                    @click="changeTab('central-units')"
                >
                    <i class="fas fa-server"></i>
                    <span class="mobile">
                        {{ $t("entityDetails.tabs.centralUnits") }}
                    </span>
                </li>
                <!-- Gestion Locative -->
                <li
                    class="list-group-item"
                    :class="{ activeTab: activeTab == 'manage' }"
                    @click="manage ? goToV1View('data/location') : editAction()"
                    v-if="!isBasic"
                >
                    <i class="fas fa-users"></i>
                    <span class="mobile">
                        {{ $t("entityDetails.tabs.manage") }}
                    </span>
                    <i
                        v-if="manage"
                        class="fas fa-check-circle manage-check mobile"
                    ></i>
                    <i
                        v-else
                        class="fas fa-times-circle manage-uncheck mobile"
                    ></i>
                </li>
                <!-- Subaccounts -->
                <li
                    class="list-group-item"
                    :class="{ activeTab: activeTab == 'subaccounts' }"
                    @click="changeTab('subaccounts'), loadSubaccounts()"
                >
                    <i class="fas fa-user-friends"></i>
                    <span class="mobile">
                        {{ $t("entityDetails.tabs.subaccounts") }}
                    </span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import v1mixin from "@/mixins/v1.js"
import { residenceType } from "@/enums"
import Infos from "@/views/residence/Infos"

export default {
    name: "LeftMenu",
    components: { Infos },
    props: [
        "type",
        "address",
        "activeTab",
        "ipass",
        "manage",
        "name",
        "comment",
    ],
    mixins: [v1mixin],
    computed: {
        isBasic() {
            return this.type === residenceType.BASIC
        },
        isSimplified() {
            return this.type === residenceType.SIMPLIFIED
        },
        isComplex() {
            return this.type === residenceType.COMPLEX
        },
    },
    methods: {
        goToResidences() {
            if (window.history.state.back.startsWith("/residences?")) {
                history.back()
            } else {
                this.$router.push({
                    name: "Residences",
                })
            }
        },
        editAction() {
            this.$emit("editAction")
        },
        changeTab(tabLabel) {
            this.$router.push({
                params: {
                    id: this.$route.params.id,
                    tab: tabLabel,
                },
            })
        },
        loadSubaccounts() {
            this.$emit("loadSubaccounts")
        },
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/global.scss";
@import "@/assets/scss/details.scss";

@media all and (min-width: 1024px) {
    .no-mobile {
        display: none;
    }
}

@media all and (max-width: 1024px) {
    .mobile {
        display: none;
    }
}
</style>
