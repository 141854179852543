<template>
    <div class="infos">
        <div class="info">
            <i class="fas fa-bookmark start title"></i>
            <div class="text title" @click="editAction">
                {{ name !== "" && name !== null ? name : "--" }}
            </div>
            <i
                v-if="checkRight('G_RESIDENCES')"
                class="fas fa-pencil-alt modify-icon end"
            ></i>
        </div>
        <div class="info">
            <i class="fas fa-map-marker-alt start title"></i>
            <div class="text title" @click="editAction">
                {{ address }}
            </div>
            <i
                v-if="checkRight('G_RESIDENCES')"
                class="fas fa-pencil-alt modify-icon end"
            ></i>
        </div>
        <div class="info">
            <i class="fas fa-comment start"></i>
            <div class="text comment" @click="editAction">
                {{ comment }}
            </div>
            <i
                v-if="checkRight('G_RESIDENCES')"
                class="fas fa-pencil-alt modify-icon end"
            ></i>
        </div>
    </div>

    <section class="image mobile">
        <div class="picto">
            <div class="big-picture">
                <img
                    alt="basic residence"
                    v-if="isBasic"
                    :src="
                        require('@/assets/images/buildings/basic-residence-medium.png')
                    "
                />
                <img
                    alt="simplified residence "
                    v-else-if="isSimplified"
                    :src="
                        require('@/assets/images/buildings/simplified-residence-medium.png')
                    "
                />
                <img
                    alt="complex residence"
                    v-else-if="isComplex"
                    :src="
                        require('@/assets/images/buildings/complex-residence-medium.png')
                    "
                />
            </div>
            <div class="icons-residence">
                <span v-show="ipass">
                    <img
                        alt="ipass icon"
                        class="ipass-icon"
                        :src="imageBaseURL + 'Images/ipass_icon.png'"
                    />
                </span>
            </div>
        </div>
    </section>
</template>

<script>
import { residenceType } from "@/enums"

export default {
    name: "Infos",
    props: ["ipass", "address", "name", "type", "comment"],
    data() {
        return {
            imageBaseURL: process.env.VUE_APP_IMAGES_URL,
        }
    },
    computed: {
        isBasic() {
            return this.type === residenceType.BASIC
        },
        isSimplified() {
            return this.type === residenceType.SIMPLIFIED
        },
        isComplex() {
            return this.type === residenceType.COMPLEX
        },
    },
    methods: {
        editAction() {
            this.$emit("editAction")
        },
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/global.scss";
@import "@/assets/scss/details.scss";

@media all and (min-width: 1024px) {
    .no-mobile {
        display: none;
    }
}
@media all and (max-width: 1024px) {
    .mobile {
        display: none;
    }
}
</style>
